import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // useNavigate 훅 가져오기

const UpdateUserpageButton = ({ userId = 1 }) => {
  const navigate = useNavigate(); // useNavigate 훅 사용
  const [userData, setUserData] = useState({
    familyRole: '',
    phoneNumber: '',
    gender: '',
    age: null, // age는 초기값을 null로 설정
  });

  // 사용자 정보를 가져오는 함수
  const fetchUserData = async () => {
    try {
      const response = await axios.get(`https://www.parking-predict.site:8888/api/mypage/update/${userId}`, {
        withCredentials: true, // 쿠키 포함
      });
      if (response.status === 200) {
        setUserData({
          ...response.data,
          age: response.data.age || null, // age가 없으면 null로 설정
        });
      }
    } catch (error) {
      console.error('Failed to fetch user data', error);
    }
  };

  // 컴포넌트가 마운트될 때 사용자 데이터를 가져옴
  useEffect(() => {
    fetchUserData();
  }, [userId]);

  // 입력값이 변경될 때 상태를 업데이트하는 함수
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // age 필드를 숫자로 변환
    if (name === 'age') {
      setUserData({ ...userData, [name]: value ? parseInt(value, 10) : null });
    } else {
      setUserData({ ...userData, [name]: value });
    }
  };

  // 수정된 데이터를 저장하는 함수
  const handleSubmit = async () => {
    try {
      // 빈 값 확인
      if (!userData.familyRole || !userData.phoneNumber || !userData.gender || userData.age === null) {
        alert('모든 필드를 올바르게 입력해주세요.');
        return;
      }

      // axios 요청에 Content-Type 헤더 추가
      await axios.patch('https://www.parking-predict.site:8888/api/mypage/update', userData, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true, // 쿠키 포함
      });

      alert('사용자 정보가 성공적으로 업데이트되었습니다.');
      navigate('/mypage'); // 페이지 이동
    } catch (error) {
      console.error('Failed to update user data', error);
      alert('사용자 정보 업데이트에 실패했습니다.');
    }
  };

  return (
    <div>
      <h2>사용자 정보 수정</h2>
      <div>
        <label>Family Role:</label>
        <input
          type="text"
          name="familyRole"
          value={userData.familyRole}
          onChange={handleInputChange}
          required
        />
      </div>
      <div>
        <label>Phone Number:</label>
        <input
          type="text"
          name="phoneNumber"
          value={userData.phoneNumber}
          onChange={handleInputChange}
          required
        />
      </div>
      <div>
        <label>Gender:</label>
        <input
          type="text"
          name="gender"
          value={userData.gender}
          onChange={handleInputChange}
          required
        />
      </div>
      <div>
        <label>Age:</label>
        <input
          type="number"
          name="age"
          value={userData.age || ''} // age가 null일 경우 빈 문자열로 설정
          onChange={handleInputChange}
          required
          min="0"
        />
      </div>
      <button onClick={handleSubmit}>정보 수정</button>
    </div>
  );
};

export default UpdateUserpageButton;
