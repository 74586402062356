import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import './CalendarData.css'; // CSS 파일 import

const CalendarData = ({ userId = 1, date = "2024-11-28" }) => {
  const [data, setData] = useState([]);
  const [bloodPressureData, setBloodPressureData] = useState(null);
  const [bloodSugarData, setBloodSugarData] = useState(null);

  // 데이터 Fetch
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://www.parking-predict.site:8888/api/calendar/day/${userId}?date=${date}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error: ${response.status}`);
        }
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [userId, date]);

  // 데이터 처리
  useEffect(() => {
    if (data.length > 0) {
      const bloodPressure = data
        .find((item) => item.type === 'bloodpresure')
        ?.measurements?.sort(
          (a, b) => new Date(a.registrationDate) - new Date(b.registrationDate)
        ) || [];

      const bloodSugar = data
        .find((item) => item.type === 'bloodsugar')
        ?.measurements?.sort(
          (a, b) => new Date(a.registrationDate) - new Date(b.registrationDate)
        ) || [];

      setBloodPressureData(bloodPressure);
      setBloodSugarData(bloodSugar);
    }
  }, [data]);

  // 그래프 데이터 생성
  const generateChartData = (measurements, type) => {
    return {
      labels: measurements.map((m) =>
        new Date(m.registrationDate).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        })
      ),
      datasets: [
        {
          label: type === 'bloodpresure' ? 'High Pressure' : 'Blood Sugar',
          data: type === 'bloodpresure'
            ? measurements.map((m) => parseFloat(m.highpressure))
            : measurements.map((m) => parseFloat(m.bloodsugar)),
          borderColor: 'rgba(75, 192, 192, 1)',
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          tension: 0.4,
        },
        ...(type === 'bloodpresure'
          ? [
              {
                label: 'Low Pressure',
                data: measurements.map((m) => parseFloat(m.lowpressure)),
                borderColor: 'rgba(255, 99, 132, 1)',
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                tension: 0.4,
              },
            ]
          : []),
      ],
    };
  };

  if (!bloodPressureData && !bloodSugarData) {
    return <div className="loading-message">Loading...</div>;
  }

  return (
    <div>
      {bloodPressureData && (
        <div className="chart-container">
          <h3>Blood Pressure</h3>
          <Line
            className="chart-canvas"
            data={generateChartData(bloodPressureData, 'bloodpresure')}
            options={{
              responsive: true,
              maintainAspectRatio: false,
            }}
          />
        </div>
      )}
      {bloodSugarData && (
        <div className="chart-container">
          <h3>Blood Sugar</h3>
          <Line
            className="chart-canvas"
            data={generateChartData(bloodSugarData, 'bloodsugar')}
            options={{
              responsive: true,
              maintainAspectRatio: false,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default CalendarData;
