// src/userpage/UpdateUserpage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const UpdateUserpage = () => {
  const navigate = useNavigate();

  // UpdateUserpageButton으로 이동하는 함수
  const goToUpdatePage = () => {
    navigate('/mypagebutton');
  };

  return (
    <div>
      <h2>Update User Page</h2>
      <p>여기에서 유저 정보를 업데이트할 수 있습니다.</p>
      <button onClick={goToUpdatePage}>정보 수정하러 가기</button>
    </div>
  );
};

export default UpdateUserpage;
