import React, { useEffect, useState } from 'react';
import { connectWebSocket, sendMessage } from './ChatWebSocket';

const ChatComponent = () => {
  const [roomId, setRoomId] = useState(''); // 현재 방 ID
  const [messages, setMessages] = useState([]); // 메시지 목록
  const [input, setInput] = useState(''); // 메시지 입력값
  const [stompClient, setStompClient] = useState(null); // WebSocket 클라이언트
  const [familyRole, setFamilyRole] = useState(null); // 사용자 역할
  const [userId, setUserId] = useState(null); // 사용자 ID

  // 유저 데이터 가져오기
  const fetchUserData = async () => {
    try {
      const response = await fetch('https://www.parking-predict.site:8888/api/userdata', {
        method: 'GET',
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setUserId(data.userId);
        setFamilyRole(data.familyRole);
      } else {
        console.error('Failed to fetch user data');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  // 채팅방 조회 또는 생성
  const fetchOrCreateRoom = async () => {
    try {
      const response = await fetch(`https://www.parking-predict.site:8888/api/rooms?userId=${userId}`, {
        method: 'POST', // 방 조회 또는 생성
      });
      if (response.ok) {
        const room = await response.json();
        setRoomId(room.roomId); // 방 ID 설정
      } else {
        console.error('Failed to fetch or create room');
      }
    } catch (error) {
      console.error('Error fetching or creating room:', error);
    }
  };

  // 특정 방의 메시지 내역 가져오기
  const fetchMessages = async (roomId) => {
    try {
      const response = await fetch(`https://www.parking-predict.site:8888/api/rooms/${roomId}/messages`, {
        method: 'GET',
      });
      if (response.ok) {
        const data = await response.json();
        setMessages(data); // 기존 메시지 상태 초기화 후 추가
      } else {
        console.error('Failed to fetch messages for room:', roomId);
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  // WebSocket 연결 설정
  useEffect(() => {
    if (!stompClient) {
      console.log('Setting up WebSocket connection...');
      const client = connectWebSocket(() => {
        console.log('WebSocket connected.');
      });
      setStompClient(client);
    }
  }, []);

  // WebSocket 구독 로직 및 메시지 내역 가져오기
  useEffect(() => {
    if (roomId && stompClient && stompClient.connected) {
      console.log('WebSocket connected. Subscribing to room:', roomId);

      // 메시지 내역 가져오기
      fetchMessages(roomId);

      const subscription = stompClient.subscribe(`/sub/chat/room/${roomId}`, (message) => {
        if (message.body) {
          setMessages((prevMessages) => [...prevMessages, JSON.parse(message.body)]);
        }
      });

      console.log(`Subscribed to room: ${roomId}`);

      // 구독 해제 로직
      return () => {
        subscription.unsubscribe();
        console.log(`Unsubscribed from room: ${roomId}`);
      };
    } else if (stompClient && !stompClient.connected) {
      console.error('STOMP client is not connected yet.');
    }
  }, [roomId, stompClient]);

  // 메시지 전송
  const handleSendMessage = () => {
    if (input.trim() && stompClient && stompClient.connected && familyRole) {
      const message = {
        type: 'TALK',
        roomId,
        sender: familyRole,
        message: input,
      };
      sendMessage(stompClient, message);
      setInput('');
    } else {
      console.error('Cannot send message: WebSocket not connected or familyRole unavailable.');
    }
  };

  // 초기 데이터 로드
  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    if (userId) fetchOrCreateRoom();
  }, [userId]);

  return (
    <div>
      <h2>Chat Room</h2>
      <h3>Room ID: {roomId}</h3>
      <div
        style={{
          border: '1px solid black',
          height: '300px',
          overflowY: 'scroll',
          padding: '10px',
        }}
      >
        {messages.map((msg, index) => (
          <div key={index}>
            <strong>{msg.sender}:</strong> {msg.message}
          </div>
        ))}
      </div>
      <input
        type="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder="Type a message..."
      />
      <button onClick={handleSendMessage}>Send</button>
    </div>
  );
};

export default ChatComponent;
