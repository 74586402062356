import React from 'react';

const LoginPage = () => {
    const handleButtonClickKaKao = () => {
        window.location.href = 'https://www.parking-predict.site:8888/oauth2/authorization/kakao';
    };
    
    const handleButtonClickNaver = () => {
        window.location.href = 'https://www.parking-predict.site:8888/oauth2/authorization/naver';
    };

    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
            <button
                onClick={handleButtonClickKaKao}
                style={{padding: '10px 20px', fontSize: '18px', borderRadius: '5px', cursor: 'pointer'}}
            >
               카카오톡 로그인
            </button>
            <button
                onClick={handleButtonClickNaver}
                style={{padding: '10px 20px', fontSize: '18px', borderRadius: '5px', cursor: 'pointer'}}
            >
               네이버 로그인
            </button>
        </div>
    );
};

export default LoginPage;
