// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode> //userpage에서 토큰저장하는 요청이 2번이나 되길래 주석처리함
        <App />
    // </React.StrictMode>
);

// 웹 성능 측정
reportWebVitals();
