import React, { useEffect, useState } from 'react';
import './MedicationManagement.css'; // 스타일 파일

function MedicationManagement() {
    const [medicationData, setMedicationData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // 데이터 가져오기 (쿠키 포함)
        const fetchData = async () => {
            try {
                const response = await fetch('http://parking-predict.site:8080/api/medicine/1?date=2024-11-15', {
                    method: 'GET',
                    credentials: 'include',
                });
                if (!response.ok) throw new Error('Failed to fetch medication data');
                const data = await response.json();
                setMedicationData(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleIntakeConfirmation = async (intakeMedicineListId, currentStatus) => {
        try {
            const response = await fetch(
                `https://www.parking-predict.site:8888/api/medicine/intake?intakeMedicineListId=${intakeMedicineListId}&intakeConfirmed=${!currentStatus}`,
                {
                    method: 'PATCH',
                    credentials: 'include',
                }
            );

            if (!response.ok) throw new Error('Failed to update intake status');

            setMedicationData((prevData) =>
                prevData.map((medication) => {
                    return {
                        ...medication,
                        medicineList: medication.medicineList.map((medicine) =>
                            medicine.intakeMedicineListId === intakeMedicineListId
                                ? { ...medicine, intakeConfirmed: !currentStatus }
                                : medicine
                        ),
                    };
                })
            );
        } catch (error) {
            console.error('Error updating intake status:', error);
        }
    };

    const handleAllIntakeConfirmation = async (medicationManagementId) => {
        console.log('모두 복용 버튼 클릭:', { medicationManagementId }); // 요청 데이터 로그

        try {
            const response = await fetch(
                `https://www.parking-predict.site:8888/api/medicine/intake/all?medicationManagementId=${medicationManagementId}`,
                {
                    method: 'PATCH',
                    credentials: 'include',
                }
            );

            console.log('서버 응답 상태:', response.status); // HTTP 상태 코드 로그

            if (!response.ok) throw new Error('Failed to update all intake status');

            const responseData = await response.text(); // 서버에서 반환된 텍스트 데이터 확인
            console.log('서버 응답 데이터 (s 값):', responseData); // s 값 로그 출력

            // 모든 약의 intakeConfirmed와 totalIntakeConfirmed를 true로 설정
            setMedicationData((prevData) =>
                prevData.map((medication) =>
                    medication.medicationManagementId === medicationManagementId
                        ? {
                              ...medication,
                              totalIntakeConfirmed: true,
                              medicineList: medication.medicineList.map((medicine) => ({
                                  ...medicine,
                                  intakeConfirmed: true,
                              })),
                          }
                        : medication
                )
            );
        } catch (error) {
            console.error('Error updating all intake status:', error);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="notification-container">
            {medicationData.map((medication, index) => (
                <div key={index} className="medication-card">
                    <div className="medication-header">
                        <div className="notification-time">
                            <span>🕒</span> {medication.notificationTime}
                        </div>
                        <div className="medication-title">
                            <span>➕</span> {medication.medicineBagName}
                        </div>
                        <div className="medication-duration">
                            {medication.notificationDate} (n일간)
                        </div>
                    </div>
                    <div className="medicine-list">
                        {medication.medicineList.map((medicine) => (
                            <div key={medicine.intakeMedicineListId} className="medicine-item">
                                <div className="medicine-info">
                                    <div className="medicine-name">{medicine.medicineName}</div>
                                    <div className="medicine-dosage">{medicine.dosagePerIntake}정</div>
                                </div>
                                <div
                                    className={`intake-status-button ${
                                        medicine.intakeConfirmed ? 'confirmed' : 'pending'
                                    }`}
                                    onClick={() =>
                                        handleIntakeConfirmation(
                                            medicine.intakeMedicineListId,
                                            medicine.intakeConfirmed
                                        )
                                    }
                                >
                                    {medicine.intakeConfirmed ? '✔️ 복용 완료' : '복용'}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div
                        className="confirm-button"
                        onClick={() => handleAllIntakeConfirmation(medication.medicationManagementId)}
                    >
                        모두 복용
                    </div>
                </div>
            ))}
        </div>
    );
}

export default MedicationManagement;
