// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBKnIfvWo8hPAwKVmh7hR0wavPS9DfEQpA",
  authDomain: "silveralarm-fb7f6.firebaseapp.com",
  projectId: "silveralarm-fb7f6",
  storageBucket: "silveralarm-fb7f6.appspot.com",
  messagingSenderId: "879072020398",
  appId: "1:879072020398:web:95b90d3259d99bdb1e2a3f",
  measurementId: "G-7PGB6EPZ1V"
};

// Firebase 초기화
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

// FCM 토큰 요청 함수
export const requestFCMToken = async () => {
  console.log('FCM 토큰 요청 중...');
  
  // 알림 권한 요청
  const permission = await Notification.requestPermission();
  if (permission === 'denied') {
    console.log('알림 권한이 거부되었습니다.');
    return null;
  }

  console.log('알림 권한이 허용되었습니다.');

  // 토큰 발급
  const token = await getToken(messaging, {
    vpaidKey: 'BD8ybePXL629MZ9kGw0qS_hOR21Iq4PSDmfg-s2KCKkQ-ktr2G-tD3DZpfS6zvJ5YSJ113sI0rIEwTKKSho7Fk4',
  });

  if (token) {
    console.log('FCM 토큰 발급 완료:', token);
    return token;
  } else {
    console.log('FCM 토큰 발급 실패');
    return null;
  }
};

// 포그라운드 메시지 수신 처리 함수
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log('메시지가 도착했습니다:', payload);

      // Extract notification data
      const title = payload.notification.title;
      const options = {
        body: payload.notification.body,
        icon: payload.notification.icon || '/default-icon.png',
        data: { url: payload.data.url || 'https://default-url.com' } // URL 정보 설정
      };

      // Show notification if permission is granted
      if (Notification.permission === 'granted') {
        const notification = new Notification(title, options);

        // Handle click event on the notification
        notification.onclick = (event) => {
          event.preventDefault(); // Prevent the browser from focusing the Notification's tab
          window.open(notification.data.url, '_blank'); // URL을 새 탭에서 열기
        };
      }

      resolve(payload);
    });
  });

// Service Worker 등록
export const registerServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
      messaging.useServiceWorker(registration);
      console.log('Service Worker 등록 성공:', registration.scope);
    } catch (err) {
      console.log('Service Worker 등록 실패:', err);
    }
  }
};
