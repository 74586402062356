// CodeGenerationPage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';

const CodeGenerationPage = () => {
    const [code, setCode] = useState(null);
    const [error, setError] = useState(null);

    const fetchNewCode = async () => {
        try {
            const response = await axios.get('https://www.parking-predict.site:8888/api/family/code', { withCredentials: true });
            setCode(response.data);
        } catch (err) {
            setError(err.response?.data || '코드 생성에 실패했습니다.');
        }
    };

    useEffect(() => {
        fetchNewCode();
    }, []);

    const styles = {
        container: {
            maxWidth: '600px',
            margin: '50px auto',
            padding: '30px',
            borderRadius: '10px',
            backgroundColor: '#f9fafb', // 전체 배경색 추가
            boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
            textAlign: 'center',
            fontFamily: 'Arial, sans-serif',
        },
        title: {
            fontSize: '28px',
            fontWeight: 'bold',
            color: '#2c3e50', // 짙은 색상으로 강조
            marginBottom: '20px',
        },
        error: {
            color: '#e74c3c', // 빨간색으로 강조
            marginBottom: '20px',
            fontWeight: 'bold',
        },
        codeContainer: {
            backgroundColor: '#ecf0f1', // 연한 회색 배경
            padding: '20px',
            borderRadius: '5px',
            fontSize: '20px',
            fontWeight: 'bold',
            color: '#34495e', // 코드 텍스트 색상
            border: '2px dashed #3498db', // 테두리에 색상 추가
        },
        loading: {
            fontSize: '18px',
            color: '#95a5a6', // 로딩 중 메시지 색상 변경
        },
        refreshButton: {
            marginTop: '20px',
            padding: '10px 20px',
            backgroundColor: '#3498db', // 버튼 배경색
            color: 'white',
            fontSize: '16px',
            fontWeight: 'bold',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            transition: 'background-color 0.3s',
        },
        refreshButtonHover: {
            backgroundColor: '#2980b9', // 버튼 호버 시 색상
        },
    };

    const [buttonHover, setButtonHover] = useState(false);

    return (
        <div style={styles.container}>
            <h1 style={styles.title}>코드 생성</h1>
            {error && <p style={styles.error}>{error}</p>}
            {code ? (
                <div>
                    <h2>가족 코드:</h2>
                    <p style={styles.codeContainer}>{code}</p>
                </div>
            ) : (
                <p style={styles.loading}>코드를 생성하는 중입니다...</p>
            )}
            <button
                style={buttonHover ? { ...styles.refreshButton, ...styles.refreshButtonHover } : styles.refreshButton}
                onClick={fetchNewCode}
                onMouseEnter={() => setButtonHover(true)}
                onMouseLeave={() => setButtonHover(false)}
            >
                가족 코드 확인
            </button>
        </div>
    );
};

export default CodeGenerationPage;
