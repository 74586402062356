import React, { useState } from "react";

const OCR = () => {
  const [imageFile, setImageFile] = useState(null);

  const GOOGLE_VISION_API_KEY = "AIzaSyBv893TcqdJGkM4c-7byvuG2zf3QhYWZyU";

  const handleFileChange = (event) => {
    setImageFile(event.target.files[0]);
  };

  const handleOCRAndSend = async () => {
    if (!imageFile) {
      console.error("이미지 파일을 선택하세요.");
      return;
    }

    try {
      // Step 1: 이미지 파일을 Base64로 변환
      const base64Image = await toBase64(imageFile);

      // Step 2: Google Vision API 호출
      const ocrResult = await fetchGoogleVisionAPI(base64Image);

      if (ocrResult) {
        // Step 3: 로컬 백엔드로 결과 전송
        const backendResponse = await sendToBackend(ocrResult);
        console.log("백엔드 응답:", backendResponse);
      }
    } catch (error) {
      console.error("오류 발생:", error);
    }
  };

  const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result.split(",")[1]; // Base64 데이터 추출
        resolve(base64String);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const fetchGoogleVisionAPI = async (base64Image) => {
    try {
      const requestBody = {
        requests: [
          {
            image: {
              content: base64Image,
            },
            features: [{ type: "TEXT_DETECTION" }],
          },
        ],
      };

      const response = await fetch(
        `https://vision.googleapis.com/v1/images:annotate?key=${GOOGLE_VISION_API_KEY}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.ok) {
        const result = await response.json();
        const text =
          result.responses[0]?.fullTextAnnotation?.text ||
          "텍스트를 인식하지 못했습니다.";
        console.log("OCR 결과:", text);
        return text;
      } else {
        console.error(
          "Google Vision API 요청 실패:",
          response.status,
          await response.text()
        );
        return null;
      }
    } catch (error) {
      console.error("Google Vision API 호출 중 오류:", error);
      return null;
    }
  };

  const sendToBackend = async (ocrText) => {
    try {
      const requestBody = {
        body: ocrText,
      };

      const response = await fetch("https://www.parking-predict.site:8888/api/ocr/process", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const result = await response.json();
        return result;
      } else {
        console.error(
          "백엔드 요청 실패:",
          response.status,
          await response.text()
        );
        return null;
      }
    } catch (error) {
      console.error("백엔드로 전송 중 오류:", error);
      return null;
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <h2>OCR 테스트</h2>
      <input type="file" accept="image/*" onChange={handleFileChange} />
      <button onClick={handleOCRAndSend} style={{ marginLeft: "10px" }}>
        OCR 실행 및 결과 전송
      </button>
    </div>
  );
};

export default OCR;
