// src/family/FamilyRegisterRequest.js
import React, { useState } from 'react';
import axios from 'axios';

const FamilyRegisterRequest = () => {
    const [familyCode, setFamilyCode] = useState('');
    const [requesterUserId, setRequesterUserId] = useState(''); // requesterUserId 상태 추가

    const handleFamilyCodeChange = (e) => {
        setFamilyCode(e.target.value);
    };

    const handleRequesterUserIdChange = (e) => {
        setRequesterUserId(e.target.value); // input 값 업데이트
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!familyCode || !requesterUserId) {
            alert("가족 코드와 사용자 ID를 모두 입력해 주세요!");
            return;
        }

        try {
            // API 요청 보내기
            const response = await axios.post('https://www.parking-predict.site:8888/api/code/request', {
                familyCode: familyCode,
                requestUserId: requesterUserId,
            });

            alert("가족 가입 요청이 성공적으로 전송되었습니다!");
            console.log(response.data);
        } catch (error) {
            alert("가족 가입 요청에 실패했습니다: " + error.response.data);
            console.error("Error:", error);
        }
    };

    return (
        <div>
            <h2>가족 코드 요청</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="familyCode">가족 코드:</label>
                    <input
                        type="text"
                        id="familyCode"
                        value={familyCode}
                        onChange={handleFamilyCodeChange}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="requesterUserId">사용자 ID:</label>
                    <input
                        type="text"
                        id="requesterUserId"
                        value={requesterUserId}
                        onChange={handleRequesterUserIdChange}
                        required
                    />
                </div>
                <button type="submit">요청 보내기</button>
            </form>
        </div>
    );
};

export default FamilyRegisterRequest;
