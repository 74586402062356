// src/login/UserPage.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { requestFCMToken } from '../firebase/firebaseConfig';
// import { useUser } from '../context/UserContext'; // Context 사용

const UserPage = () => {
    // const { setUser } = useUser(); // Context에서 setUser 가져오기
    const navigate = useNavigate();

    useEffect(() => {
        fetch('https://www.parking-predict.site:8888/api/userdata', {
            method: 'GET',
            credentials: 'include',
        })
        .then(response => response.json())
        .then(async data => {
            // 유저 정보 저장
            // setUser({ name: data.name, userId: data.userId });
            console.log('name: ', data.name,'userId: ',data.userId);

            const token = await requestFCMToken();
            if (token) {
                fetch('https://www.parking-predict.site:8888/api/save-token', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        token: token,
                        userId: data.userId,
                    }),
                    credentials: 'include',
                })
                .then(response => response.json())
                .then(result => {
                    console.log('토큰 서버 전송 완료:', result);
                })
                .catch(error => {
                    console.error('토큰 전송 실패:', error);
                });
            }
            if (data.familyRole !== null) {
                navigate('/test');}
            if (data.familyRole === null) {
                navigate('/userextra');
            } else {
                navigate('/main');
            }
        })
        .catch(error => {
            console.error('유저 정보 가져오기 실패:', error);
        });
    }, [navigate]);

    return (
        <div>
            <h1>Loading...</h1>
        </div>
    );
};

export default UserPage;
