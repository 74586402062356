import React, { useEffect, useState } from 'react';
import axios from 'axios';

const MainPage = () => {
    const [name, setName] = useState('');
    const [userId, setUserId] = useState('');

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get('http://ec2-52-78-143-45.ap-northeast-2.compute.amazonaws.com:8080/api/userdata', {
                    withCredentials: true // 쿠키를 포함하여 요청
                });
                localStorage.setItem('user', JSON.stringify(response.data)); // 사용자 정보 저장
                setName(response.data.name); // 유저 이름 설정
                setUserId(response.data.userId); // 유저 ID 설정
            } catch (error) {
                console.error('유저 데이터를 가져오는 중 오류 발생:', error);
            }
        };

        fetchUserData();
    }, []);

    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h1>환영합니다, {name}님!</h1>
        </div>
    );
};

export default MainPage;
