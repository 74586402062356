import React, { useState, useEffect } from 'react';
import './Notification.css'; // CSS 파일을 import

const Notification = () => {
    const [userId, setUserId] = useState(null); // userId를 상태로 관리
    const [notifications, setNotifications] = useState([]);
    const [error, setError] = useState(null);

    // 사용자 정보를 가져오는 함수
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch('https://www.parking-predict.site:8888/api/userdata', {
                    method: 'GET',
                    credentials: 'include', // 쿠키 포함
                });

                if (!response.ok) {
                    throw new Error('유저 정보를 가져오는 중 오류가 발생했습니다.');
                }

                const data = await response.json();
                console.log('유저 정보:', data); // 유저 정보 로그 출력

                // userId를 상태에 저장
                setUserId(data.userId);
            } catch (err) {
                setError('유저 데이터를 가져오는 중 오류가 발생했습니다.');
                console.error(err); // 에러 로그 출력
            }
        };

        fetchUserData();
    }, []); // 컴포넌트가 처음 렌더링될 때만 호출

    // 알림 데이터를 가져오는 함수
    useEffect(() => {
        if (!userId) {
            // userId가 없을 경우 알림을 가져오지 않음
            return;
        }

        const fetchNotifications = async () => {
            try {
                const response = await fetch(
                    `https://www.parking-predict.site:8888/api/notification?userId=${userId}`,
                    {
                        method: 'GET',
                        credentials: 'include', // 쿠키를 포함하도록 설정
                    }
                );

                if (!response.ok) {
                    throw new Error('알림 데이터를 가져오는 중 오류가 발생했습니다.');
                }

                const data = await response.json();
                console.log('알림 데이터:', data);
                setNotifications(data);
            } catch (err) {
                setError(err.message);
                console.error(err); // 알림 데이터를 가져오는 중 에러 로그 출력
            }
        };

        fetchNotifications();
    }, [userId]); // userId 상태가 변경될 때마다 알림 데이터를 요청

    if (error) {
        return <div className="notification-error">{error}</div>;
    }

    return (
        <div className="notification-container">
            <h2 className="notification-header">알림 목록</h2>
            {notifications.length > 0 ? (
                <ul className="notification-list">
                    {notifications.map((notification) => (
                        <li key={notification.notificationId} className="notification-item">
                            <div className="notification-title">{notification.type}</div>
                            <div className="notification-body">{notification.message}</div>
                        </li>
                    ))}
                </ul>
            ) : (
                <p>알림이 없습니다.</p>
            )}
        </div>
    );
};

export default Notification;
