// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './login/LoginPage';
import MainPage from './MainPage';
import UserPage from './login/UserPage';
import UserDataPage from './login/UserDataPage';
import { AuthProvider } from './login/AuthContext';
import LogoutPage from './login/LogoutPage';
import CodeGenerationPage from './family/CodeGenerationPage';
import { UserProvider } from './context/UserContext';
import FamilyRegisterRequest from './family/FamilyRegisterRequest';
import FamilyCodeAlarm from './alarm/FamilyCodeAlarm';
import { registerServiceWorker, onMessageListener } from './firebase/firebaseConfig';
import Notification from './notificaftion/Notification'
import MedicationManagement from './medicine/MedicationManagement'
import Calendar from './calendar/Calendar'
import UpdateUserpageButton from './userpage/UpdateUserpageButton'
import UpdateUserpage from './userpage/UpdateUserpage'
import CalendarData from './calendar/CalendarData'
import ChatComponent from './chat/ChatComponent'
import OCR from './ocr/OCR'
import FamilyUnlink from './family/FamilyUnlink'
// import MedicineSearch from './search/MedicineSearch'


const App = () => {
    useEffect(() => {
        // Service Worker 등록
        registerServiceWorker();

        // 포그라운드 알림 수신 리스너 설정
        onMessageListener()
            .then((payload) => {
                console.log('포그라운드 메시지가 도착했습니다:', payload);

                // 알림 데이터 추출
                const { title, body } = payload.notification;
                const data = payload.data;

                // 알림을 화면에 표시
                alert(`알림: ${title}\n${body}\n추가 데이터: ${JSON.stringify(data)}`);
            })
            .catch((err) => console.log('메시지 수신 중 오류 발생:', err));
    }, []);

    return (
        <UserProvider>
            <Router>
                <Routes>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/main" element={<MainPage />} />
                    <Route path="/" element={<LoginPage />} />
                    <Route path="/successpage" element={<UserPage />} />
                    <Route path="/userextra" element={<UserDataPage />} />
                    <Route path="/logout" element={<LogoutPage />} />
                    <Route path="/codegeneration" element={<CodeGenerationPage />} />
                    <Route path="/familycode" element={<FamilyRegisterRequest />} />
                    <Route path="/alarm" element={<FamilyCodeAlarm />} />
                    <Route path="/notification" element={<Notification />} />
                    <Route path="/medication" element={<MedicationManagement />} />
                    <Route path="/calendar" element={<Calendar />} />
                    <Route path="/mypagebutton" element={<UpdateUserpageButton />} />
                    <Route path="/mypage" element={<UpdateUserpage />} />
                    <Route path="/calendardata" element={<CalendarData />} />
                    <Route path="/chat" element={<ChatComponent />} />
                    <Route path="/ocr" element={<OCR />} />
                    <Route path="/unfamily" element={<FamilyUnlink />} />
                    {/* <Route path="/search" element={<MedicineSearch />} /> */}
                    
                </Routes>
            </Router>
        </UserProvider>
    );
};

export default App;
