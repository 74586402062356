import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Calendar.css'; // 스타일을 위한 CSS 파일

const Calendar = ({ userId = 3 }) => {
  const [calendarData, setCalendarData] = useState([]);
  const [monthDays, setMonthDays] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());

  // 현재 연도와 월을 포맷팅하는 함수
  const formatDate = (date) => {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
  };

  // 데이터 가져오기
  useEffect(() => {
    const fetchData = async () => {
      try {
        const formattedDate = formatDate(currentDate);
        const response = await axios.get(`https://www.parking-predict.site:8888/api/calendar/${userId}`, {
          params: { date: formattedDate },
          withCredentials: true,
        });
        // 데이터가 배열인지 확인하고 설정
        console.log("calendarData : ",calendarData)
        setCalendarData(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
        console.error("데이터 가져오기 실패:", error);
        setCalendarData([]); // 오류 발생 시 빈 배열로 설정
      }
    };

    fetchData();
  }, [userId, currentDate]);

  // 현재 월의 날짜 계산
  useEffect(() => {
    const calculateDaysInMonth = () => {
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth();
      const firstDay = new Date(year, month, 1);
      const lastDay = new Date(year, month + 1, 0);
      const daysArray = [];

      for (let day = firstDay; day <= lastDay; day.setDate(day.getDate() + 1)) {
        daysArray.push(new Date(day));
      }

      setMonthDays(daysArray);
    };

    calculateDaysInMonth();
  }, [currentDate]);

  // 이벤트 날짜인지 확인하는 함수
  const getEventTitles = (day) => {
    // calendarData가 배열인지 확인한 후에만 filter 사용
    if (!Array.isArray(calendarData)) return [];

    return calendarData
      .filter((item) =>
        item.target.some(
          (target) => new Date(target.targetMonth).toDateString() === day.toDateString()
        )
      )
      .map((item) => item.calendarTitle);
  };

  // 다음 달로 이동
  const nextMonth = () => {
    const next = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
    setCurrentDate(next);
  };

  // 이전 달로 이동
  const prevMonth = () => {
    const prev = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
    setCurrentDate(prev);
  };

  return (
    <div className="calendar-container">
      <div className="calendar-header">
        <button onClick={prevMonth}>이전 달</button>
        {currentDate.toLocaleString("ko-KR", { year: "numeric", month: "long" })}
        <button onClick={nextMonth}>다음 달</button>
      </div>
      <div className="calendar-grid">
        {monthDays.map((day, index) => (
          <div key={index} className="calendar-day">
            <div>{day.getDate()}</div>
            {getEventTitles(day).map((title, idx) => (
              <div key={idx} className="event-title">{title}</div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Calendar;
