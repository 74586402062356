// LogoutPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const LogoutPage = () => {
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await fetch('https://www.parking-predict.site:8888/api/logout', {
                method: 'POST',
                credentials: 'include', // 쿠키를 포함시켜 요청
            });
            navigate('/login'); // 로그아웃 후 로그인 페이지로 리디렉션
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    return (
        <div>
            <h2>Logging out...</h2>
            <button onClick={handleLogout}>Logout</button>
        </div>
    );
};

export default LogoutPage;
