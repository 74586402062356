// src/alarm/FamilyCodeAlarm.js
import React, { useEffect, useState } from 'react';
import { onMessageListener } from '../firebase/firebaseConfig';

const FamilyCodeAlarm = () => {
    const [notificationData, setNotificationData] = useState(null);

    useEffect(() => {
        // FCM 메시지 수신 리스너 설정
        const unsubscribe = onMessageListener()
            .then(payload => {
                console.log('메시지가 도착했습니다:', payload); 
                const { title, body } = payload.notification;
                const { requestUserId, url, name } = payload.data;

                // 알림 데이터 설정
                setNotificationData({
                    title,
                    body,
                    requestUserId,
                    url,
                    name
                });
            })
            .catch(err => console.log('메시지 수신 중 오류 발생:', err));

        // 컴포넌트 언마운트 시 리스너 해제
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, []);

    // 수락 버튼 핸들러
    const handleAccept = async () => {
        await sendResponse(notificationData.requestUserId, true);
    };

    // 거절 버튼 핸들러
    const handleReject = async () => {
        await sendResponse(notificationData.requestUserId, false);
    };

    // 서버로 요청 전송 함수
    const sendResponse = async (userId, isAccepted) => {
        try {
            const response = await fetch('/api/response', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    requestUserId: userId,
                    bool: isAccepted
                }),
            });
            const result = await response.json();
            console.log('서버 응답:', result);
            alert(isAccepted ? '요청을 수락했습니다.' : '요청을 거절했습니다.');
        } catch (error) {
            console.error('응답 전송 중 오류 발생:', error);
        }
    };

    return (
        <div>
            {notificationData ? (
                <div style={{
                    width: '300px',
                    padding: '20px',
                    backgroundColor: '#f0f0f0',
                    textAlign: 'center',
                    border: '1px solid #ccc',
                    borderRadius: '8px'
                }}>
                    <h2>{notificationData.title}</h2>
                    <p>{notificationData.body}</p>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '20px'
                    }}>
                        <button onClick={handleAccept} style={{
                            flex: '1',
                            marginRight: '10px',
                            padding: '10px',
                            backgroundColor: '#4CAF50',
                            color: 'white',
                            border: 'none',
                            borderRadius: '5px'
                        }}>수락</button>
                        <button onClick={handleReject} style={{
                            flex: '1',
                            marginLeft: '10px',
                            padding: '10px',
                            backgroundColor: '#f44336',
                            color: 'white',
                            border: 'none',
                            borderRadius: '5px'
                        }}>거절</button>
                    </div>
                </div>
            ) : (
                <p>알림을 기다리는 중...</p>
            )}
        </div>
    );
};

export default FamilyCodeAlarm;
