import React, { useState } from 'react';

function FamilyUnlink() {
  const [userId, setUserId] = useState(''); // 사용자 ID 입력 상태
  const [loading, setLoading] = useState(false); // 로딩 상태
  const [message, setMessage] = useState(''); // 서버 메시지 상태

  // 탈퇴 요청 처리
  const handleFamilyUnlink = async () => {
    if (!userId.trim()) {
      setMessage('User ID를 입력해주세요.');
      return;
    }

    try {
      setLoading(true); // 로딩 상태 활성화
      setMessage(''); // 이전 메시지 초기화

      const response = await fetch(`https://www.parking-predict.site:8888/api/family/code/${userId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'text/plain',
        },
        credentials: 'include',
      });

      if (response.ok) {
        const successMessage = await response.text();
        setMessage(`탈퇴 성공: ${successMessage}`);
      } else {
        const errorMessage = await response.text();
        setMessage(`탈퇴 실패: ${errorMessage || '알 수 없는 오류가 발생했습니다.'}`);
      }
    } catch (error) {
      console.error('Error unlinking family:', error);
      setMessage('오류: 서버와의 연결 중 문제가 발생했습니다.');
    } finally {
      setLoading(false); // 로딩 상태 비활성화
    }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>가족 탈퇴</h1>

      {/* 사용자 ID 입력 */}
      <input
        type="text"
        placeholder="User ID 입력"
        value={userId}
        onChange={(e) => setUserId(e.target.value)}
        style={styles.input}
      />

      {/* 탈퇴 버튼 */}
      <button onClick={handleFamilyUnlink} style={styles.button} disabled={loading}>
        {loading ? '처리 중...' : '탈퇴하기'}
      </button>

      {/* 메시지 출력 */}
      {message && <p style={styles.message}>{message}</p>}
    </div>
  );
}

// 간단한 스타일 정의
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    fontFamily: 'Arial, sans-serif',
  },
  title: {
    fontSize: '24px',
    marginBottom: '20px',
  },
  input: {
    padding: '10px',
    fontSize: '16px',
    marginBottom: '20px',
    width: '300px',
    borderRadius: '5px',
    border: '1px solid #ccc',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    color: '#fff',
    backgroundColor: '#FF6F61',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  message: {
    marginTop: '20px',
    fontSize: '16px',
    color: '#555',
  },
};

export default FamilyUnlink;
