import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const UserDataPage = () => {
    const [familyRole, setFamilyRole] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [gender, setGender] = useState('');
    const [age, setAge] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        const userData = { familyRole, phoneNumber, gender, age };

        // 백엔드로 POST 요청
        fetch('https://www.parking-predict.site:8888/api/extrainfo', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Failed to submit data');
            }
            // HTTP 상태 코드만 확인하므로 본문은 읽지 않음
            navigate('/main');  // 성공 시 /main 페이지로 이동
        })
        .catch(error => {
            console.error('Error submitting data:', error);
            setError('Failed to submit data. Please try again.');
        });
    };

    return (
        <div>
            <h2>추가 정보 입력</h2>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <form onSubmit={handleSubmit}>
                <div>
                    <label>구성원 별명: </label>
                    <input
                        type="text"
                        value={familyRole}
                        onChange={(e) => setFamilyRole(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>전화번호: </label>
                    <input
                        type="text"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>성별: </label>
                    <input
                        type="text"
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>나이: </label>
                    <input
                        type="number"
                        value={age}
                        onChange={(e) => setAge(e.target.value)}
                        required
                    />
                </div>
                <button type="submit">제출</button>
            </form>
        </div>
    );
};

export default UserDataPage;
